import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tag"];

  connect() {
    console.log('tags connected 2')
  }
  async captureClick(event) {
    event.preventDefault();
    console.log('captureClick')
    // this.clickedController = this.linkTarget == event.target;
  }

  removeTag(event) {
    console.log(event.params)
    console.log('remove_tag')
  }
}
