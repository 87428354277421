import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "sidebar", "content" ]
  static classes = [ "collapse" ]

  toggle() {
    this.element.classList.toggle(this.collapseClass);
  }
}
